import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { defaultTheme, earlyCareersTheme, isOnEcDomain } from '../../theme';
// Ideally we'd used the LuxonAdapter, however, within vuetify they don't seem to be testing the non-default adapter
// extensively, especially in v-calendar while its in labs. Therefore, let's use the default for the time being.
// import LuxonAdapter from '@date-io/luxon';

// const luxon = new LuxonAdapter({ locale: 'en-GB' });

export default createVuetify({
  // date: {
  //   adapter: luxon,
  // },
  theme: {
    defaultTheme: isOnEcDomain() ? 'earlyCareers' : 'ITT',
    themes: { ITT: defaultTheme, earlyCareers: earlyCareersTheme },
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 3,
      darken: 1,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  // Aim to get rid of this and use Mosaic* components instead
  defaults: {
    VTextField: {
      variant: 'underlined',
    },
    VSelect: {
      variant: 'underlined',
    },
    VAutocomplete: {
      variant: 'underlined',
    },
  },
});
